import { AuthenticatedTemplate } from '@azure/msal-react';

import { useRoles } from '../../hooks/useRoles';

export default function Account() {
	const { name } = useRoles();

	return (
		<AuthenticatedTemplate>
			<div className="account">
				{ name && (
					<div className="account__greeting">
						Hi, { name }
					</div>
				)}
			</div>
		</AuthenticatedTemplate>
	);
};
