import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Loading from "react-loading";
import cn from "classnames";

import Icon from "../Icon";

import { usePhotos } from "../../hooks/usePhotos";
import { useRoles } from "../../hooks/useRoles";

export default function SearchBar({
  size = "large",
  onSubmit = function () {},
  isLoading = false,
  isError = false,
  clearError = function () {},
}) {
  const { query } = useRouter();
  const { searchText: initialSearch } = usePhotos();
  const { checkAccess } = useRoles();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setSearchText(query?.truckId || initialSearch);
  }, [initialSearch, query]);

  const spinnerDimensions =
    size === "large" ? { width: 34, height: 34 } : { width: 24, height: 24 };

  const handleSubmit = useCallback(
    (e) => {
      clearError();

      onSubmit(e, searchText);
    },
    [clearError, onSubmit, searchText]
  );

  return (
    <form
      className={cn("search__form", `search__form--${size}`, {
        "search__form--error": isError,
      })}
      onSubmit={handleSubmit}
    >
      <input
        className="search__input"
        type="text"
        placeholder="Enter a vin or stock number..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />

      <button className="button button--search-submit" type="submit">
        {!isLoading ? (
          <Icon icon="magnify" />
        ) : (
          <Loading type="spin" color="#676767" {...spinnerDimensions} />
        )}
      </button>
    </form>
  );
}
