import Link from 'next/link';

import { getYear } from '../../services/format';

function FooterNav({ children }) {
	return (
		<div className="site-footer__nav">
			{ children }
		</div>
	);
}

function FooterNavItemNext({ href, children }) {
	return (
		<div className="site-footer__item">
			<Link href={ href }>
				<a className="site-footer__link">{ children }</a>
			</Link>
		</div>
	);
}

function FooterNavItem({ href, target, children }) {
	return (
		<div className="site-footer__item">
			<a className="site-footer__link" target={target} href={ href }>{ children }</a>
		</div>
	);
}

export default function Footer() {
	return (
    <footer className="site-footer">
      <div className="site-footer__primary">
        <div className="site-footer__inner">
          <div className="container">
            <img
              src="/csm-truck-light.png"
              width="158"
              height=""
              alt="CSM Truck"
            />

            <FooterNav>
              <FooterNavItem
                target="_blank"
                href={`${process.env.NEXT_PUBLIC_ASSETS_ENDPOINT}/pdfs/TruckPhotoGuidelines-v1.pdf`}
              >
                Photo Upload Guidelines
              </FooterNavItem>

              <FooterNavItem href="https://www.csmtruck.com/">
                CSM Website
              </FooterNavItem>

              <FooterNavItem href="mailto:tech.help@csmtruck.com?subject=Truck%20Image%20Management%20App">
                Contact CSM
              </FooterNavItem>
            </FooterNav>
          </div>
        </div>
      </div>

      <div className="site-footer__secondary">
        <div className="site-footer__inner">
          <div className="container">
            <small>
              &copy; {getYear()},{" "}
              <a
                className="button--text"
                href="https://www.csmtruck.com/csm-companies-inc"
              >
                CSM Companies
              </a>{" "}
              <span className="footer-separator">|</span>{" "}
              <span className="footer-trademark">
                Trademarks and brands are the property of their respective
                owners.
              </span>
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
}
