import { Fragment } from 'react';

export default function Breadcrumbs({ links = [] }) {
	if ( links.length === 0 ) {
		return <></>;
	}

	return (
		<div className="breadcrumbs">
			{ links.map( link => (
				<Fragment key={ link.id }>
					{ link.label && (
						<div className="breadcrumbs__item">
							{ link.href && <a className="breadcrumbs__link" href={ link.href }>{ link.label }</a> }

							{ !link.href && <span className="breadcrumbs__label">{ link.label }</span> }
						</div>
					)}
				</Fragment>
			))}
		</div>
	);
}
