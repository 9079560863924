import { useRef, useState } from "react";
import { getDetails } from "../services/truckAssets";

function useTruckCheck() {
  const [isTruckFound, setIsTruckFound] = useState(false);
  const [foundTruck, setFoundTruck] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const truckChecks = useRef([]);

  async function checkForTruck(vin) {
    if (!vin) {
      return;
    }

    setIsLoading(true);

    const existingCheck = truckChecks.current.find(
      (check) => check.vin === vin
    );

    if (existingCheck) {
      setIsTruckFound(existingCheck.isActive);
      setFoundTruck(existingCheck.vin);
      setIsLoading(false);

      return;
    }

    try {
      let returnObj = {};

      const truckCheck = await getDetails(vin);

      if (truckCheck.status === 404) {
        returnObj = { vin, isActive: false };

        setFoundTruck(vin);
        setIsTruckFound(false);
      } else {
        returnObj = { vin, isActive: true };

        setFoundTruck(vin);
        setIsTruckFound(true);
      }

      truckChecks.current = [...truckChecks.current, returnObj];

      return returnObj;
    } catch (err) {
      console.error("There was an error searching for an existing truck", err);

      setFoundTruck(null);
      setIsTruckFound(false);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isTruckFound,
    foundTruck,
    isLoading,
    checkForTruck,
  };
}

export { useTruckCheck };
