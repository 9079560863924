import { useRouter } from "next/router";

import Account from "../Account";
import Breadcrumbs from "../Breadcrumbs";
import SearchBar from "../SearchBar";

import { useTruckCheck } from "../../hooks/useTruckCheck";

export default function Header({ loading, headerSearch, breadcrumbs }) {
  const router = useRouter();

  const { isLoading, checkForTruck } = useTruckCheck();

  const submitSearch = async (e, searchText) => {
    e.preventDefault();

    const checkResults = await checkForTruck(searchText);

    if (checkResults) {
      if (checkResults.isActive) {
        router.push(`/truck/${searchText}?`);
      } else {
        router.push(`/?truckId=${searchText}`);
      }
    }
  };

  return (
    <>
      <header className="header">
        <div className="header__inner">
          <div className="logo">
            <a href="/">
              <img
                className="logo__image"
                src="/csm-truck.png"
                width="217"
                height="27"
                alt="CSM Truck"
              />
            </a>
          </div>

          {!loading && headerSearch && (
            <SearchBar
              size="small"
              onSubmit={submitSearch}
              isLoading={isLoading}
            />
          )}

          <Account />
        </div>
      </header>

      {!loading && <Breadcrumbs links={breadcrumbs} />}
    </>
  );
}
