import dayjs from 'dayjs';
import prettyBytes from 'pretty-bytes';

export function formatDate( datestring ) {
	return dayjs( datestring ).format( 'MM/DD/YYYY' );
}

export function getYear() {
	return dayjs().format( 'YYYY' );
}

export function getPrettyFilesize( fileSize ) {
	if ( !fileSize || Number( fileSize ) === NaN ) {
		return null;
	}

	return prettyBytes( Number( fileSize ) );
}
