import { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { loginRequest } from '../../services/msal';

import Head from 'next/head';

import Footer from '../Footer';
import Header from '../Header';

import { useRoles } from '../../hooks/useRoles';

function ErrorComponent({ error }) {
	return <div>An authentication error occured: { error.errorCode }</div>;
}

function Loading() {
	return <div>Authentication in progress...</div>;
}

function Unauthorized() {
	return (
		<div className="site-wrap">
			<div className="container">
				<h1>Unauthorized</h1>

				<p>You are not authorized to access this content.</p>
			</div>
		</div>
	);
}

export function ProtectedContent({ children }) {
	const [ mounted, setMounted ] = useState( false );

	useEffect( () => {
		setMounted( true );
	}, [] );

	const authRequest = {
		...loginRequest
	};

	return (
		mounted && (
			<MsalAuthenticationTemplate
				interactionType={ InteractionType.Redirect }
				authenticationRequest={ authRequest }
				errorComponent={ ErrorComponent }
				loadingComponent={ Loading }
			>
				{ children }
			</MsalAuthenticationTemplate>
		)
	);
}

export function Layout({ children, breadcrumbs, headerSearch = true, loading }) {
	return (
		<>
			<Head>
				<title>Truck Inventory and Service Image Manager</title>

				<meta name="description" content="" />

				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

				<link rel="icon" href="/semi-icon_32x32.png" sizes="32x32" />
				<link rel="icon" href="/semi-icon_192x192.png" sizes="192x192" />
				<link rel="apple-touch-icon" href="/semi-icon_192x192.png" />
			</Head>

			<div className="site-wrap">
				<Header loading={ loading } headerSearch={ headerSearch } breadcrumbs={ breadcrumbs } />

				<main>
					{ children }
				</main>

				<Footer />
			</div>
		</>
	);
}

export default function ProtectedLayout({ children, headerSearch, breadcrumbs, loading }) {
	const { rolesMap, checkAccess } = useRoles();

	if ( checkAccess( rolesMap.unauthorized ) ) {
		return <Unauthorized />
	}

	return (
		<ProtectedContent>
			<Layout headerSearch={ headerSearch } breadcrumbs={ breadcrumbs } loading={ loading }>
				{ children }
			</Layout>
		</ProtectedContent>
	);
}
